import React, { useEffect, useState, useRef, memo } from "react"
import Swiper from "swiper"
import Constants from "../constants/api"
import Loader from "../components/loader"
import Join from "../components/join"
import SwiperSlide from "../components/swiperslide"
import "../templates/river.css"

function IndexRiver(props) {
  const { sites } = props
  let siteKey = {}
  for (let key in sites) {
    siteKey[sites[key].node.mysqlId] = sites[key].node
  }
  const g = useRef(null)
  const handleClickSimple = () => {
    setIsJoin(j => !j)
  }
  //   const slug = props.pageContext.slug
  //   const modelName = props.pageContext.model_name
  //   const options = props.pageContext.options
  //   const siteid = props.pageContext.siteid
  const maybe = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  // const [showSnapCode, setShowSnapCode] = useState(false)
  //const [snapcodeModel, setSnapcodeModel] = useState({})
  const [slideInfo, setSlideInfo] = useState("0.0")
  const [joinOptions, setJoinOptions] = useState({
    slug: "af",
    siteid: 0,
    modelName: "",
    def_nats: "",
    options: [
      {
        optionid: 109,
        cost: "$1 / 1 Day",
        small: "Billed Monthly @ $24.99",
        limited_time: "n",
        strikeprice: "",
      },
      {
        optionid: 110,
        cost: "$0.66 / Day",
        small: "$19.99 / Month Special",
        limited_time: "y",
        strikeprice: "$29.99",
      },
      {
        optionid: 107,
        cost: "$0.27 / Day",
        small: "Recurring, Billed @ $98.76 / Year",
        limited_time: "n",
        strikeprice: "",
      },
    ],
  })
  const [isJoin, setIsJoin] = useState(false)
  // const popSnapCode = modelSite => {
  //   if (modelSite.free_snapchat !== "none") {
  //     setShowSnapCode(true)
  //     setSnapcodeModel(modelSite)
  //   }
  // }
  useEffect(() => {
    if (isJoin) {
      document.body.classList.add("show_form")
    } else {
      document.body.classList.remove("show_form")
    }

    return () => document.body.classList.remove("show_form")
  }, [isJoin])
  const handleClick = (site, riverID = "0") => {
    if (maybe && "current" in maybe && "realIndex" in maybe.current) {
      setSlideInfo(`${riverID}.${maybe.current.realIndex}`)
    } else {
      setSlideInfo("0.0")
    }
    const options = siteKey[site.id].options
    setJoinOptions({
      slug: site.site_url,
      def_nats: siteKey[site.id].def_nats,
      siteid: site.id,
      modelName: site.site_name,
      options: options,
    })
    // slug={slug}
    //     siteid={siteid}
    //     modelName={modelName}
    //     def_nats={currentNats}
    //     options={options}
    handleClickSimple()
  }

  const [virtualData, setVirtualData] = useState({
    offset: 0,
    from: 0,
    to: 0,
    slides: [],
    isDesktop: false,
  })

  useEffect(() => {
    const AFSwiper = data => {
      setIsLoading(false)
      if (g.current) {
        maybe.current = new Swiper(g.current, {
          direction: "vertical",
          preloadImages: false,
          on: {
            init() {
              setIsLoading(false)
            },
          },
          virtual: {
            slides: data,
            renderExternal(data) {
              setVirtualData(data)
            },
            addSlidesBefore: 1,
            addSlidesAfter: 1,
          },
        })
      }
    }
    fetch(`${Constants.RIVER}model-river/0`)
      .then(res => res.json())
      .then(res => {
        setIsLoading(false)
        AFSwiper(res)
      })
  }, [])
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="river swiper-container" ref={g}>
        <div className="swiper-wrapper">
          {virtualData.slides.map(river => {
            return (
              <SwiperSlide
                key={`${river.id}ir`}
                river={river}
                topOffSet={virtualData.offset}
                handleClick={() => handleClick(river.site, `${river.id}`)}
                videoCount={siteKey[river.site.id].sitestat.videos}
              />
            )
          })}
        </div>
      </div>
      {isJoin ? (
        <Join
          handleClick={handleClickSimple}
          slug={joinOptions.slug}
          siteid={joinOptions.siteid}
          modelName={joinOptions.modelName}
          def_nats={joinOptions.def_nats}
          options={joinOptions.options}
          theSlideInfo={slideInfo}
        />
      ) : null}
      {/* {showSnapCode ? (
        <div className="showSnapCode">
          <i className="icon-close" onClick={() => setShowSnapCode(false)} />
          <img src={`/${snapcodeModel.site_url}-snapchat.svg`} alt="" />
          <h2>
            My Free Snapchat Username is:{" "}
            <strong>{snapcodeModel.free_snapchat}</strong>
          </h2>
        </div>
      ) : null} */}
    </>
  )
}

export default memo(IndexRiver, () => true)

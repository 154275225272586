import React, { useState } from "react"
// import { Link } from "gatsby"

const SwiperSlide = props => {
  const randomNumber = (min, max) => {
    return Math.random() * (+max - +min) + +min
  }
  const { river, topOffSet, handleClick } = props
  const [volume, setVolume] = useState(false)
  const [likes] = useState(() => randomNumber(1, 9))
  const [chats] = useState(() => Math.round(randomNumber(10, 99)))
  // const [views] = useState(() => Math.round(randomNumber(3893, 9999)))
  const toggleVolume = () => {
    setVolume(!volume)
  }

  return (
    <div className="swiper-slide" style={{ top: topOffSet }}>
      <div className="side_navigation">
        <div className="row">
          <div className="user" onClick={handleClick}>
            <img
              src={`https://static.addfriends.com/images/${river.site.site_url}.jpg`}
              alt={river.site.site_name}
            />
          </div>
        </div>
        <div className="row">
          <div className="item" onClick={handleClick}>
            <i className="icon-empty-heart" />
            <span>{Math.round(10 * likes) / 10}k</span>
          </div>
        </div>
        <div className="row">
          <div className="item buttonMsg" onClick={handleClick}>
            <i className="icon-message" />
            <span>{chats}</span>
          </div>
        </div>
        <div className="row">
          <button
            type="button"
            className="button mVolume"
            onClick={toggleVolume}
          >
            <i className={volume ? "icon-sound-full" : "icon-sound-off"} />
          </button>
        </div>
        {/* <div className="row">
          <div className="count_inform">
            <div className="col">
              <strong>{videoCount}</strong> <span>videos</span>
            </div>
          </div>
        </div> */}
      </div>
      <div className="user_information">
        <div className="name" onClick={handleClick}>
          <strong> {river.site.site_name}</strong>
        </div>
        <div className="information">{river.msg}</div>
        <div className="bottom">
          {/* <div className="item">
            <span>{views}</span>k views
          </div>
          <div className="item">{river.length}</div> */}

          <div className="item">{river.created_at}</div>
        </div>
      </div>
      <div className="user_container">
        {river.river_type === "photo" ? (
          <img
            src={`https://static.addfriends.com/vids/${river.site.site_url}/${river.file_name}-river.jpg`}
            alt={`Join ${river.site.site_name} Picture # ${river.id}`}
          />
        ) : (
          <video
            className="cropeVideo"
            autoPlay
            loop
            muted={!volume ? true : false}
            playsInline
            preload="auto"
            onClick={handleClick}
          >
            <source
              src={`https://static.addfriends.com/vids/${river.site.site_url}/${river.file_name}.mp4`}
              type="video/mp4"
            />
          </video>
        )}
      </div>
      {/* <div
        className="user_container"
        onClick={handleClick}
        dangerouslySetInnerHTML={{
          __html: `
        <video
          loop
          muted=${volume}
          autoplay
          playsinline
          class="cropeVideo"
        ><source
        src=${`https://static.addfriends.com/vids/${river.site.site_url}/${river.file_name}.mp4`}
        type="video/mp4"
      /></video>
      `,
        }}
      ></div> */}
      {/* <div className="cta">
        <button className="thumb_btn" onClick={handleClick}>
          Click to <strong>join</strong>
        </button>
      </div> */}
    </div>
  )
}

export default SwiperSlide

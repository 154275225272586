import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Desktop from "../components/desktop"
import IndexRiver from "../components/index-river"
import Loader from "../components/loader"
import SEO from "../components/seo"
import VerifyAge from "../components/VerifyAge"

const shuffle = a => {
  var j, x, i
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1))
    x = a[i]
    a[i] = a[j]
    a[j] = x
  }
  return a
}
const IndexPage = ({ data, location }) => {
  const sites = shuffle(
    data.allMysqlSites.edges.filter(node => node.node.is_active === "y")
  )
  const [isLoading, setIsLoading] = useState(true)
  const [ageVerify, setAgeVerify] = useState(false)

  const [windowWidth, setWindowWidth] = useState(0)
  useEffect(() => {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth
    setWindowWidth(x)
    setIsLoading(false)
  }, [])
  useEffect(() => {
    const getCookie = name => {
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)
      if (parts.length === 2)
        return parts
          .pop()
          .split(";")
          .shift()
    }
    const age = getCookie("agAF")
    if (age !== "yes") {
      setAgeVerify(true)
    }
  }, [])
  return (
    <Layout location={location} isRiver={false}>
      <SEO title="Home" />
      {isLoading ? (
        <Loader />
      ) : windowWidth > 768 ? (
        <Desktop sites={sites} data={data} />
      ) : (
        <IndexRiver sites={sites} />
      )}
      {ageVerify && <VerifyAge setAgeVerify={setAgeVerify} />}
    </Layout>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    allMysqlSites(filter: { show_tour: { eq: "y" } }) {
      edges {
        node {
          free_snapchat
          site_name
          site_url
          def_nats
          is_active
          mysqlId
          options {
            optionid
            cost
            small
            limited_time
            strikeprice
            coin
          }
          sitestat {
            likes
            videos
          }
        }
      }
    }
  }
`
export default IndexPage

import React, { useEffect } from "react"
import "../age.css"
const VerifyAge = ({ setAgeVerify }) => {
  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("age-veri")
    document.getElementsByTagName("body")[0].classList.add("age-veri")
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("age-veri")
      document.getElementsByTagName("body")[0].classList.remove("age-veri")
    }
  })
  return (
    <div className="age-container">
      <div className="age-holder">
        <div className="age-content">
          <h2>
            Please Read And Comply With The Following Conditions Before You
            Continue.
          </h2>
          <div className="age-legal">
            <p>
              By accepting this agreement, I certify that I am over the age of
              consent in my jurisdiction and that I do not find images of nude
              adults, adults engaged in hardcore sex or other sexual porn videos
              and photos to be offensive or objectionable in any way. I am at
              least 18 years old and have the legal right to possess adult
              material, porn videos, naughty pictures and intimate erotic
              stories in my community.
            </p>
            <p>
              I understand the standards and laws of my own community and I am
              solely responsible for my own actions. I will not attempt to
              bypass any security or access features of the Add Friends site
              without permission. If I use these services in violation of the
              above agreement, I understand I may be in violation of local or
              federal laws and I am solely responsible for my actions.
            </p>
            <p>
              By entering AddFriends.com or viewing any of the amateur models
              having sex in videos produced on this site, I fully release and
              discharge the providers, owners and creators of this adult
              entertainment website from any and all liability. Book marking to
              a page on this server/site whereby this warning page is bypassed
              shall constitute an implicit acceptance of the foregoing terms
              herein set forth.
            </p>
            <p>
              By accepting this agreement, I certify that I am over the age of
              consent in my jurisdiction and that I do not find images of nude
              adults, adults engaged in hardcore sex or other sexual porn videos
              and photos to be offensive or objectionable in any way. I am at
              least 18 years old and have the legal right to possess adult
              material, porn videos, naughty pictures and intimate erotic
              stories in my community.
            </p>
            <p>
              I understand the standards and laws of my own community and I am
              solely responsible for my own actions. I will not attempt to
              bypass any security or access features of the Add Friends site
              without permission. If I use these services in violation of the
              above agreement, I understand I may be in violation of local or
              federal laws and I am solely responsible for my actions.
            </p>
            <p>
              By entering AddFriends.com or viewing any of the amateur models
              having sex in videos produced on this site, I fully release and
              discharge the providers, owners and creators of this adult
              entertainment website from any and all liability. Book marking to
              a page on this server/site whereby this warning page is bypassed
              shall constitute an implicit acceptance of the foregoing terms
              herein set forth.
            </p>
          </div>
          <div className="age-yesno">
            <div
              className="ageBtn"
              onClick={() => {
                document.cookie = "agAF=yes"
                setAgeVerify(false)
              }}
            >
              Enter AddFriends
            </div>
          </div>
          <div className="age-exit">
            I disagree -{" "}
            <a href="https://news.google.com/topstories" rel="nofollow">
              Exit Here
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyAge
